@import "flags.css";
.react-tel-input .form-control {
    /* position: relative;
    font-size: 14px;
    letter-spacing: 0.01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important; */
    padding-left: 48px;
    /* margin-left: 0;
    background: #ffffff;
    border: 1px solid #cacaca;
    line-height: 25px;
    height: 35px;
    width: 300px;
    outline: none; */
}
.react-tel-input .form-control.invalid-number {
    /* border: 1px solid #d79f9f;
    background-color: #faf0f0;
    border-left-color: #cacaca; */
}
.react-tel-input .form-control.invalid-number:focus {
    /* border: 1px solid #d79f9f;
    border-left-color: #cacaca;
    background-color: #faf0f0; */
}
.react-tel-input .flag-dropdown {
    outline: none;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    /* border: 1px solid #cacaca; */
}
.react-tel-input .flag-dropdown.open {
    z-index: 2;
    /* background: #fff; */
}
.react-tel-input .flag-dropdown.open .selected-flag {
    /* background: #fff; */
}
.react-tel-input .flag-dropdown:hover,
.react-tel-input .flag-dropdown:focus,
.react-tel-input .flag-dropdown.open {
    cursor: pointer;
}
.react-tel-input .flag-dropdown:hover .selected-flag,
.react-tel-input .flag-dropdown:focus .selected-flag,
.react-tel-input .flag-dropdown.open .selected-flag {
    /* background-color: #f5f5f5; */
}
.react-tel-input input[disabled] + .flag-dropdown:hover {
    cursor: default;
}
.react-tel-input input[disabled] + .flag-dropdown:hover .selected-flag {
    background-color: transparent;
}
.react-tel-input .selected-flag {
    position: relative;
    width: 38px;
    height: 100%;
    padding: 0 0 0 12px;
    /* border-radius: 3px 0 0 3px; */
    outline: none;
}
.react-tel-input .selected-flag .flag {
    position: absolute;
    top: 50%;
    margin-top: -5px;
}
.react-tel-input .selected-flag .arrow {
    position: relative;
    top: 50%;
    margin-top: -2px;
    left: 20px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #555;
}
.react-tel-input .selected-flag .arrow.up {
    border-top: none;
    border-bottom: 4px solid #555;
}
.react-tel-input .country-list {
    z-index: 1;
    list-style: none;
    position: absolute;
    padding: 0;
    margin: 0 0 10px -1px;
    background-color: white;
    /* border: 1px solid #cacaca; */
    outline: none;
    width: 292px;
    max-height: 282px;
    overflow-y: scroll;
}
.react-tel-input .country-list .flag {
    display: inline-block;
}
.react-tel-input .country-list .divider {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #eeedf4;
}
.react-tel-input .country-list .country {
    padding: 5px 9px;
}
.react-tel-input .country-list .country .dial-code {
    color: #9191a1;
}
.react-tel-input .country-list .country:hover {
    background-color: #f8f7f9;
}
.react-tel-input .country-list .country.highlight {
    background-color: #f8f7f9;
}
.react-tel-input .country-list .flag {
    margin-right: 7px;
    margin-top: 2px;
}
.react-tel-input .country-list .country-name {
    margin-right: 6px;
}
.react-tel-input .country-list .search {
    position: sticky;
    top: 0;
    background-color: #fff;
}
.react-tel-input .country-list .search-emoji {
    font-size: 15px;
    position: absolute;
    top: 5px;
    left: 9px;
}
.react-tel-input .country-list .search-box {
    border: none;
    /* border-bottom: 1px solid #cacaca; */
    width: 100%;
    font-size: 15px;
    line-height: 15px;
    padding: 5px 8px 5px 32px;
    outline: none;
}
.react-tel-input .country-list .no-entries-message {
    padding: 7px 10px 11px;
    opacity: 0.7;
}
.react-tel-input .invalid-number-message {
    position: absolute;
    z-index: 1;
    font-size: 13px;
    left: 46px;
    top: -8px;
    background: #fff;
    padding: 0 2px;
    /* color: #de0000; */
}
  .special-label {
    display: none;
    /* position: absolute;
    z-index: 1;
    font-size: 13px;
    left: 46px;
    top: -8px;
    background: #fff;
    padding: 0 2px;
    white-space: nowrap; */
  }
