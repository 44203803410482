.customSlider .slick-prev {
  color: rgb(83, 90, 224) !important;
  fill: rgb(83, 90, 224) !important;
  z-index: 999999 !important;
  left: 3% !important;
}

.customSlider .slick-next {
  color: rgb(83, 90, 224) !important;
  fill: rgb(83, 90, 224) !important;
  z-index: 999999 !important;
  right: 3% !important;
}

.slick-prev:before, .slick-next:before {
  color: rgb(83, 90, 224) !important;
}

